let mobileMenu;
document.addEventListener(
    "DOMContentLoaded", () => {
        mobileMenu = new Mmenu( "#mobileNav", {
            "extensions": [
                "pagedim-black",
                "position-front",
                "position-right"
            ]
        }, {
            offCanvas: {
                page: {
                    selector: "#pageContainer",
                },
            },
        });

        const api = mobileMenu.API;
        const links = document.querySelectorAll('.mm-listitem__text[href="javascript:void(0)"]');
        links.forEach((l) => {
            l.addEventListener("click", (e) => {
                e.preventDefault();
                const panelId = l.nextElementSibling.getAttribute("href");
                const panel = document.querySelector(panelId);
                if (!panelId || !panel) return;
                api.openPanel(panel);
            });
        });
    }
);

